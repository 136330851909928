import { useState, useRef } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  Typography,
  Stack,
  Divider,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { DenseCardHeader, CardForm, FormDateTimePicker } from "..";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import dayjs from "dayjs";
//--------------------------------------------------------------------------------------------------//
//--------------------------------------------------------------------------------------------------//
const plans = ["الباقة الفضية", "الباقة الذهبية", "الباقة الماسية"];
//--------------------------------------------------------------------------------------------------//
//--------------------------------------------------------------------------------------------------//
const FilterForm = ({ toggleFormModal, setFilter, setQuery, setPagination, handlePageChange, type }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    //user filter form
    subscription: null,
    subscriptionEndDate: null,
    plan: null,
    book: null,
    watchStartDate: null,
    watchEndDate: null,
    //book filter form
    createdAt: null,
    //transactions filter form
    transStartDate: null,
    transEndDate: null,
    paymentResult: null,
  });
  const formikRef = useRef();
  //--------------------------------------------------------------------------------------------------//
  //--------------------------------------------------------------------------------------------------//
  const kickFilteration = async () => {
    if (type === "forUsersTable") {
      setQuery("");
      setPagination({ total: 0, currentPage: 0 });
      handlePageChange(0);
    }
    setFilter({ ...formData });
    toggleFormModal(false);
  };
  //--------------------------------------------------------------------------------------------------//
  //--------------------------------------------------------------------------------------------------//
  const handleChanges = (e) => {
    formData[e.target.name] = e.target.value ? e.target.value : "";

    setFormData({ ...formData });
  };
  //--------------------------------------------------------------------------------------------------//
  //--------------------------------------------------------------------------------------------------//
  return (
    <>
      {/* -------------------------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------------------------- */}
      <Formik
        initialValues={{
          ...formData,
        }}
        onSubmit={kickFilteration}
        innerRef={formikRef}
      >
        <Form>
          <CardForm style={{ borderRadius: "0px" }}>
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            <DenseCardHeader title={"خيارات البحث"} className="h-16 p-2"></DenseCardHeader>
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            {type === "forUsersTable" && (
              <CardContent className="pb-2">
                <Grid container spacing={2}>
                  {/* -----------------Subscription-------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    <Typography variant="h6" dir="rtl">
                      الإشتراك
                    </Typography>
                    {/* -------- */}
                    <label>
                      <Checkbox
                        type="checkbox"
                        name="subscription"
                        value="active"
                        checked={formData.subscription === "active"}
                        onChange={handleChanges}
                      />
                      الساري
                    </label>
                    {/* -------- */}
                    <label>
                      <Checkbox
                        type="checkbox"
                        name="subscription"
                        value="inactive"
                        checked={formData.subscription === "inactive"}
                        onChange={handleChanges}
                      />
                      المنتهي
                    </label>
                    {/* -------- */}
                    <label>
                      <Checkbox
                        type="checkbox"
                        name="subscription"
                        value="unsubscribed"
                        checked={formData.subscription === "unsubscribed"}
                        onChange={handleChanges}
                      />
                      غير مشترك
                    </label>
                    {/* -------- */}
                  </Grid>
                  {/* --------------------End Date--------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      تاريخ إنتهاء الإشتراك
                    </Typography>
                    {/* -------- */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          sx={{
                            width: "100%",
                            "& .MuiInputBase-root": {
                              width: "100%",
                              fontFamily: "Droid Serif",
                              flexDirection: "row-reverse",
                            },
                          }}
                          value={formData.subscriptionEndDate ? dayjs(formData.subscriptionEndDate) : null}
                          name="subscriptionEndDate"
                          label={t("admin.plan.form.endDate")}
                          localeText={{
                            toolbarTitle: t("dateTimePicker.toolbarTitle"),
                            okButtonLabel: t("dateTimePicker.okButtonLabel"),
                            cancelButtonLabel: t("dateTimePicker.cancelButtonLabel"),
                            clearButtonLabel: t("dateTimePicker.clearButtonLabel"),
                            todayButtonLabel: t("dateTimePicker.todayButtonLabel"),
                          }}
                          onChange={(newValue) => {
                            formData.subscriptionEndDate = new Date(newValue.$d).getTime();
                            setFormData({ ...formData });
                          }}
                        ></DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  {/* ----------------------Plan----------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      الباقة
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">الباقة</InputLabel>
                      <Select name="plan" label={"الباقة"} onChange={handleChanges}>
                        {plans.map((plan) => (
                          <MenuItem key={plan} value={plan}>
                            {plan}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* ----------------------Book----------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      جميع المستخدمين الذين استمعوا بالكامل إلى
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">اسم الكتاب</InputLabel>
                      <TextField name="book" type="string" label="اسم الكتاب" value={formData.book} fullWidth onChange={handleChanges} />
                    </FormControl>
                  </Grid>
                  {/* -------------------Watch Start Date-------------------- */}
                  {/* ------------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      تاريخ بدء المشاهدة
                    </Typography>
                    {/* -------- */}
                    <FormDateTimePicker
                      itemDate={formData.watchStartDate}
                      name="watchStartDate"
                      label="تاريخ بدء المشاهدة"
                      onAccept={(date) => {
                        formData.watchStartDate = new Date(date).toISOString();
                        setFormData({ ...formData });
                      }}
                    ></FormDateTimePicker>
                  </Grid>
                  {/* --------------------Watch End Date--------------------- */}
                  {/* ------------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      تاريخ نهاية المشاهدة
                    </Typography>
                    {/* -------- */}
                    <FormDateTimePicker
                      itemDate={formData.watchEndDate}
                      name="watchEndDate"
                      label="تاريخ نهايةالمشاهدة"
                      onAccept={(date) => {
                        formData.watchEndDate = new Date(date).toISOString();
                        setFormData({ ...formData });
                      }}
                    ></FormDateTimePicker>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            {type === "forBooksTable" && (
              <CardContent className="pb-2">
                <Grid container spacing={2}>
                  {/* ------------------created Date------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      تاريخ الإنشاء
                    </Typography>
                    {/* -------- */}
                    <FormDateTimePicker
                      itemDate={formData.createdAt}
                      name="createdAt"
                      label="تاريخ الإنشاء"
                      onAccept={(date) => {
                        setFormData({
                          createdAt: new Date(date).toISOString(),
                        });
                      }}
                    ></FormDateTimePicker>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            {type === "forTransactionsTable" && (
              <CardContent className="pb-2">
                <Grid container spacing={2}>
                  {/* ----------------------Plan----------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      الباقة
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">الباقة</InputLabel>
                      <Select name="plan" label={"الباقة"} onChange={handleChanges}>
                        {plans.map((plan) => (
                          <MenuItem key={plan} value={plan}>
                            {plan}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* -------------------Trans Start Date-------------------- */}
                  {/* ------------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      المعاملات ابتداً من
                    </Typography>
                    {/* -------- */}
                    <FormDateTimePicker
                      itemDate={formData.transStartDate}
                      name="transStartDate"
                      label="المعاملات ابتداً من"
                      onAccept={(date) => {
                        formData.transStartDate = new Date(date).toISOString();
                        setFormData({ ...formData });
                      }}
                    ></FormDateTimePicker>
                  </Grid>
                  {/* --------------------Trans End Date--------------------- */}
                  {/* ------------------------------------------------------- */}
                  <Grid item xs={12}>
                    {/* -------- */}
                    <Typography variant="h6" dir="rtl">
                      المعاملات حتى
                    </Typography>
                    {/* -------- */}
                    <FormDateTimePicker
                      itemDate={formData.transEndDate}
                      name="transEndDate"
                      label="المعاملات حتى"
                      onAccept={(date) => {
                        formData.transEndDate = new Date(date).toISOString();
                        setFormData({ ...formData });
                      }}
                    ></FormDateTimePicker>
                  </Grid>
                  {/* -----------------Trans Status-------------------- */}
                  {/* ------------------------------------------------- */}
                  <Grid item xs={12}>
                    <Typography variant="h6" dir="rtl">
                      حالة الدفع
                    </Typography>
                    {/* -------- */}
                    <Box>
                      <label>
                        <Checkbox type="checkbox" name="paymentResult" value="A" checked={formData.paymentResult === "A"} onChange={handleChanges} />
                        تمت الموافقة
                      </label>
                      <label>
                        <Checkbox type="checkbox" name="paymentResult" value="P" checked={formData.paymentResult === "P"} onChange={handleChanges} />
                        قيد الإنتظار
                      </label>
                    </Box>
                    <Box>
                      <label>
                        <Checkbox type="checkbox" name="paymentResult" value="X" checked={formData.paymentResult === "X"} onChange={handleChanges} />
                        منهية الصلاحية
                      </label>

                      <label>
                        <Checkbox type="checkbox" name="paymentResult" value="D" checked={formData.paymentResult === "D"} onChange={handleChanges} />
                        مرفوض
                      </label>

                      <label>
                        <Checkbox type="checkbox" name="paymentResult" value="C" checked={formData.paymentResult === "C"} onChange={handleChanges} />
                        ألغيت
                      </label>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
            <CardActions>
              <div className="w-[100%]">
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                  <Button variant="info" onClick={kickFilteration}>
                    <SearchIcon />
                    بحث
                  </Button>
                  <Button
                    variant="text"
                    color="red"
                    onClick={() => {
                      toggleFormModal(false);
                    }}
                  >
                    <Close />
                    إلغاء
                  </Button>
                </Stack>
              </div>
            </CardActions>
            {/* ------------------------------------------------------------------------- */}
            {/* ------------------------------------------------------------------------- */}
          </CardForm>
        </Form>
      </Formik>
      {/* -------------------------------------------------------------------------------------- */}
      {/* -------------------------------------------------------------------------------------- */}
    </>
  );
};
export default FilterForm;
