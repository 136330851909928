import { axios, requests } from "../../index";

const adminBooksInfoLoader = async () => {
  const { data } = await axios.get(`${requests.admin.books}/info`);

  if (data && data.booksInfo) {
    return data.booksInfo;
  }
  return null;
};

export default adminBooksInfoLoader;
